import React from 'react';
import { TextField } from '@material-ui/core';
import DebounceTextField from 'src/components/DebounceTextField';
import NumberFormat from 'react-number-format';
import { isNumber } from 'lodash';

export const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ value }) => {
        onChange({
          target: {
            name: props.name,
            value
          }
        });
      }}
    />
  );
};

const Input = ({ debounce, delay, ...rest }) => {
  return debounce ? (
    <DebounceTextField delay={delay} {...rest} />
  ) : (
    <TextField {...rest} />
  );
};

const Number = ({
  data,
  debounce = true,
  debounceDelay = 500,
  onChangeField
}) => {
  const numberComponentProps = data?.numberComponentProps || {};

  function handleChangeField(event) {
    const editValue = event.target.value;
    let value = isNumber(editValue) ? editValue : +editValue;

    if (value === '' || isNaN(value) || typeof value === 'undefined') value = 0;

    onChangeField({
      target: {
        name: data?.fld || '',
        value
      }
    });
  }

  return (
    <Input
      label={data?.caption ?? ''}
      value={data?.value || ''}
      onChange={handleChangeField}
      name={data?.fld ?? ''}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          style: { textAlign: 'right' },
          ...numberComponentProps
        }
      }}
      disabled={data?.disabled ?? false}
      variant="outlined"
      fullWidth
      error={data?.error ?? false}
      helperText={data?.errorMessage ?? ''}
      id={data?.fld || ''}
      InputLabelProps={{
        shrink: true
      }}
      debounce={debounce}
      delay={debounceDelay}
      {...(data?.rest || {})}
    />
  );
};

export default Number;
