import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMap, Circle } from 'react-leaflet';
import {
  icon as LIcon,
  marker as LMarker,
  circle as LCircle,
  divIcon
} from 'leaflet';
import ReactDomServer from 'react-dom/server';

const icon = LIcon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: '/static/icons/marker-icon.png',
  shadowUrl: '/static/icons/marker-shadow.png'
});

const createDivIcon = content =>
  divIcon({
    className: 'custom-text-icon',
    html: ReactDomServer.renderToString(
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '5px',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          textAlign: 'center',
          height: '100px',
          width: '200px',
          overflow: 'auto'
        }}
      >
        <div style={{ maxHeight: '100%', overflow: 'hidden' }}>
          <p style={{ margin: 0, fontWeight: 700, color: '#333' }}>{content}</p>
        </div>
      </div>
    )
  });

/**
 *
 * @param {L.Circle[]} circles
 * @param {*} mark
 * @returns
 */
function isWithinRadius(circles, mark) {
  const markerPos = mark.getLatLng();

  for (const circle of circles) {
    const circleCenter = circle.getLatLng();

    if (circleCenter.distanceTo(markerPos) < circle.getRadius()) return true;
  }

  return false;
}

const LocationMarker = ({ position, setPosition, markerRef, viewOnly }) => {
  const map = useMap();

  function locate() {
    map.locate().on('locationfound', e => {
      setPosition(e.latlng);
      map.flyTo(e.latlng, 18, { duration: 1 });
    });
  }

  useEffect(() => {
    if (viewOnly) return;

    const intervalId = setInterval(locate, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [map, viewOnly]);

  return position.lat && position.lng ? (
    <Marker position={position} icon={icon} ref={markerRef} />
  ) : null;
};

/**
 * @typedef {{lat:number,lng:number}} LatLng
 */

/**
 * @typedef {object} targetLocations
 * @property {number} id
 * @property {number} lat
 * @property {number} lng
 * @property {number} radius
 * @property {string} name
 */

/**
 *
 * @param {object} props
 * @param {LatLng} props.position
 * @param {(position:LatLng)=>void} props.setPosition
 * @param {(withinRadius:boolean)=>void} props.setWithinRadius
 * @param {targetLocations[]} props.targets
 * @param {any} props.mapContainerRef
 * @param {VoidFunction} props.whenReady
 * @returns
 */
const Map = ({
  position = { lat: 0, lng: 0 },
  setPosition = () => {},
  setWithinRadius = () => {},
  targets = [],
  mapContainerRef = null,
  whenReady = () => {},
  viewOnly = false,
  zoom = 13
}) => {
  const markerRef = useRef();
  const circleRefs = useRef([]);

  useEffect(() => {
    circleRefs.current = circleRefs.current.slice(0, targets.length);
  }, [targets]);

  useEffect(() => {
    if (circleRefs.current && markerRef.current) {
      const circles = circleRefs.current.map(ref =>
        LCircle(ref._latlng, { radius: ref._mRadius })
      );
      const marker = LMarker(markerRef.current._latlng);

      const withinRadius = isWithinRadius(circles, marker);
      if (withinRadius) setWithinRadius(withinRadius);
    }
  }, [markerRef.current, circleRefs.current]);

  return (
    <MapContainer
      center={position}
      zoom={zoom}
      scrollWheelZoom={false}
      touchZoom={false}
      doubleClickZoom={false}
      style={{ height: 350, width: '100%' }}
      zoomControl={false}
      dragging={false}
      preferCanvas
      ref={mapContainerRef}
    >
      {targets.map((target, index) => (
        <React.Fragment key={target?.id || index}>
          <Circle
            ref={el => (circleRefs.current[index] = el)}
            center={target}
            radius={target?.radius || 8}
            fillColor="blue"
          />
          <Marker position={target} icon={createDivIcon(target.name, '10px')} />
        </React.Fragment>
      ))}
      <TileLayer
        eventHandlers={{
          load: whenReady
        }}
        attribution={false}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker
        position={position}
        setPosition={setPosition}
        markerRef={markerRef}
        viewOnly={viewOnly}
      />
    </MapContainer>
  );
};

export default Map;
