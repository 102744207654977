import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { useDynamicPages } from '../../DynamicPageProvider';

const useStyles = makeStyles({
  particle1: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/beyond-accounting/payroll/obj1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      top: '-20%',
      left: 0,
      aspectRatio: 1,
      height: 400
    }
  },
  particle2: {
    position: 'relative',
    zIndex: 10,
    '&:before': {
      content: '""',
      zIndex: -10,
      position: 'absolute',
      backgroundImage: 'url(/static/beyond-accounting/payroll/particle3.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      aspectRatio: 1,
      height: 200,
      top: '10%',
      right: '5%',
      transform: 'rotate(-10deg)'
    }
  }
});

const Features = () => {
  const { particle1, particle2 } = useStyles();
  const { pageDetails } = useDynamicPages();
  const features = pageDetails?.jsonSettings?.features ?? {
    sectionTitle: 'Features',
    items: []
  };

  function getParticles(type) {
    return (
      {
        particle1,
        particle2
      }[type] ?? ''
    );
  }

  function renderTextWithLinks(text, urlData) {
    if (!urlData || !Array.isArray(urlData)) {
      return text.replace(/<</g, '').replace(/>>/g, '');
    }

    return text.split(/<<|>>/).map((part, index) => {
      const match = urlData.find(({ linkText }) => linkText === part);
      if (match) {
        const { link, text_color, underline } = match;
        return (
          <a
            key={index}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: text_color || 'inherit',
              textDecoration: underline ? 'underline' : 'none'
            }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  }

  return (
    <Box position="relative" zIndex={10}>
      <Box minHeight={600} py={10}>
        <Typography
          align="center"
          style={{
            fontSize: '3.8rem',
            fontWeight: 900,
            marginBottom: '0.4em',
            position: 'relative',
            zIndex: 20
          }}
        >
          {features.sectionTitle}
        </Typography>

        {features.items.map((item, i) => (
          <Box
            key={item.id}
            mt={8}
            className={getParticles(item?.particle ?? '')}
          >
            <Container maxWidth="lg">
              <Grid
                container
                spacing={10}
                direction={
                  i % 2 === 0 && Boolean(item?.img) ? 'row-reverse' : 'row'
                }
                justifyContent="center"
              >
                <Grid
                  item
                  lg={!Boolean(item?.img) ? 12 : 6}
                  md={!Boolean(item?.img) ? 12 : 6}
                  sm={!Boolean(item?.img) ? 12 : 10}
                  xs={!Boolean(item?.img) ? 12 : 12}
                >
                  <Box>
                    <Typography style={{ fontSize: '2.8rem', fontWeight: 900 }}>
                      {renderTextWithLinks(item?.primaryText ?? '', item?.url)}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '1.8rem'
                      }}
                    >
                      {renderTextWithLinks(
                        item?.secondaryText ?? '',
                        item?.url
                      )}
                    </Typography>

                    {Boolean(item?.list) && (
                      <Box mt={3}>
                        {(item?.list ?? []).map(listItem => (
                          <Typography
                            style={{
                              fontSize: '1.8rem',
                              fontWeight: 700,
                              marginLeft: `${listItem.leftMargin ?? 0}em`
                            }}
                            key={listItem.key}
                          >
                            <FontAwesomeIcon
                              size="xs"
                              color="#ff7704"
                              icon={
                                listItem.style === 'circle'
                                  ? faCircle
                                  : faAnglesRight
                              }
                            />{' '}
                            {listItem.text}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Grid>
                {Boolean(item?.img) && (
                  <Grid item lg={6} md={6} sm={10} xs={12}>
                    <img
                      src={`/api/pages/${
                        pageDetails?.ixPage
                      }/images/${item.img ?? ''}`}
                      width={'100%'}
                    />
                  </Grid>
                )}
              </Grid>
            </Container>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Features;
