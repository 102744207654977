import Earning from './Earning';
import Deduction from './Deduction';
import Loan from './Loan';
import Log from './Log';
import Leave from './Leave';
import LinkedLeave from './LinkedLeave';
import { Request } from 'src/helpers';
import { isNumber } from 'lodash';
import { trans } from 'src/lib/endpoints';
import { getPropValue } from 'src/utils';
import Geotag from './Geotag';

const employeeDefaultProps = {
  ixTrans: null,
  ixPR: null,
  ixEmp: '',
  sEmp: '',
  MSal: 0,
  BscPay: 0,
  Rate1: 0,
  Rate2: 0,
  Remarks: '',
  ixBrch: 0,
  ixDept: 0,
  ixDept2: 0,

  vHrs: 0,
  vPresent: 0,
  vAbsent: 0,
  vLate: 0,
  vUT: 0,

  vOT: 0,
  vOT_RH: 0,
  vOT_SH: 0,
  vOT_ND: 0,
  vOT_ND_RH: 0,
  vOT_ND_SH: 0,
  vOT_RD: 0,
  vOT_RD_OT: 0,
  vOT_RD_RH: 0,
  vOT_RD_SH: 0,

  vND: 0,
  vND_RH: 0,
  vND_SH: 0,
  vND_RD: 0,
  vND_RD_RH: 0,
  vND_RD_SH: 0,

  vOB: 0,
  vRH: 0,
  vSH: 0,
  vSL: 0,
  vVL: 0,
  vPL: 0,
  vML: 0,

  var1: 0,
  var2: 0,
  var3: 0,
  var4: 0,
  var5: 0,
  var6: 0,
  var7: 0,
  var8: 0,
  var9: 0,

  D0: 0,
  D1: 0,
  D2: 0,
  D3: 0,
  D4: 0,
  D5: 0,
  D6: 0,
  DT: 0
};

class Employee {
  // If this two is not null, it means that employee is for updating
  // else for adding
  ixTrans;
  ixPR;

  ixEmp;
  sEmp;
  MSal;
  BscPay;
  Rate1;
  Rate2;
  Remarks;
  ixBrch;
  ixDept;
  ixDept2;

  vHrs;
  vPresent;
  vAbsent;
  vLate;
  vUT;

  vOT;
  vOT_RH;
  vOT_SH;
  vOT_ND;
  vOT_ND_RH;
  vOT_ND_SH;
  vOT_RD;
  vOT_RD_OT;
  vOT_RD_RH;
  vOT_RD_SH;

  vND;
  vND_RH;
  vND_SH;
  vND_RD;
  vND_RD_RH;
  vND_RD_SH;

  vOB;
  vRH;
  vSH;
  vSL;
  vVL;
  vPL;
  vML;

  var1;
  var2;
  var3;
  var4;
  var5;
  var6;
  var7;
  var8;
  var9;

  D0;
  D1;
  D2;
  D3;
  D4;
  D5;
  D6;
  DT;

  info = {};
  prInfo = {};

  /**
   * @type {Earning[]}
   */
  earnings = [];

  /**
   * @type {Deduction[]}
   */
  deductions = [];

  /**
   * @type {Loan[]}
   */
  loans = [];

  /**
   * @type {Log[]}
   */
  logs = [];

  /**
   * @type {Leave[]}
   */
  leaves = [];

  /**
   * @type {LinkedLeave[]}
   */
  linkedLeaves = [];

  /**
   * @type {Leave[]}
   */
  leavesForApproval = [];

  /**
   * @type {Geotag[]}
   */
  geotags = [];

  /**
   * @typedef {Object} notification
   * @property {'info' | 'warning' | 'error'} severity
   * @property {'change-salary' | 'negative-net'} code
   * @property {String} message
   */

  /**
   * @type {notification[]}
   */
  notifications = [];

  constructor(employeeProps = employeeDefaultProps) {
    const props = { ...employeeDefaultProps, ...employeeProps };

    // If this two is not null, it means that employee is for updating
    // else for adding
    this.ixTrans = props.ixTrans;
    this.ixPR = props.ixPR;

    this.ixEmp = props.ixEmp;
    this.sEmp = props.sEmp;
    this.MSal = props.MSal;
    this.BscPay = props.BscPay;
    this.Rate1 = props.Rate1;
    this.Rate2 = props.Rate2;
    this.Remarks = props.Remarks;
    this.ixBrch = props.ixBrch;
    this.ixDept = props.ixDept;
    this.ixDept2 = props.ixDept2;

    this.vHrs = props.vHrs;
    this.vPresent = props.vPresent;
    this.vAbsent = props.vAbsent;
    this.vLate = props.vLate;
    this.vUT = props.vUT;

    this.vOT = props.vOT;
    this.vOT_RH = props.vOT_RH;
    this.vOT_SH = props.vOT_SH;
    this.vOT_ND = props.vOT_ND;
    this.vOT_ND_RH = props.vOT_ND_RH;
    this.vOT_ND_SH = props.vOT_ND_SH;
    this.vOT_RD = props.vOT_RD;
    this.vOT_RD_OT = props.vOT_RD_OT;
    this.vOT_RD_RH = props.vOT_RD_RH;
    this.vOT_RD_SH = props.vOT_RD_SH;

    this.vND = props.vND;
    this.vND_RH = props.vND_RH;
    this.vND_SH = props.vND_SH;
    this.vND_RD = props.vND_RD;
    this.vND_RD_RH = props.vND_RD_RH;
    this.vND_RD_SH = props.vND_RD_SH;

    this.vOB = props.vOB;
    this.vRH = props.vRH;
    this.vSH = props.vSH;
    this.vSL = props.vSL;
    this.vVL = props.vVL;
    this.vPL = props.vPL;
    this.vML = props.vML;

    this.var1 = props.var1;
    this.var2 = props.var2;
    this.var3 = props.var3;
    this.var4 = props.var4;
    this.var5 = props.var5;
    this.var6 = props.var6;
    this.var7 = props.var7;
    this.var8 = props.var8;
    this.var9 = props.var9;

    this.D0 = props.D0;
    this.D1 = props.D1;
    this.D2 = props.D2;
    this.D3 = props.D3;
    this.D4 = props.D4;
    this.D5 = props.D5;
    this.D6 = props.D6;
    this.DT = props.DT;
  }

  setInfo(info = {}) {
    this.info = info;

    const kvs = info?.kvs || [];
    this.prInfo = kvs.find(item => item.key === 'pr')?.value || {};

    return this;
  }

  /**
   *
   * @param {Loan[]} loans
   */
  setLoans(loans = []) {
    this.loans = loans;
    return this;
  }

  /**
   *
   * @param {Leave[]} leaves
   */
  setLeaves(leaves = []) {
    this.leaves = leaves;
    return this;
  }

  /**
   *
   * @param {Geotag[]} geotags
   * @returns
   */
  setGeotags(geotags = []) {
    this.geotags = geotags;
    return this;
  }

  /**
   *
   * @param {Leave[]} leavesForApproval
   */
  setLeavesForApproval(leavesForApproval = []) {
    this.leavesForApproval = leavesForApproval;
    return this;
  }

  /**
   *
   * @param {LinkedLeave[]} leaves
   */
  setLinkedLeaves(leaves = []) {
    this.linkedLeaves = leaves;
    return this;
  }

  /**
   *
   * @param {Earning[]} earnings
   */
  setEarnings(earnings = []) {
    this.earnings = earnings;
    return this;
  }

  /**
   *
   * @param {Deduction[]} earnings
   */
  setDeductions(deductions = []) {
    this.deductions = deductions;
    return this;
  }

  /**
   *
   * @param {Number[]} [excludedDeductions = []]
   * @returns {Number}
   */
  getDeductionsTotalAmt(excludedDeductions = []) {
    return this.deductions
      .filter(deduction => !excludedDeductions.includes(deduction.ixDed))
      .reduce((accumulator, current) => +current.EeAmt + accumulator, 0);
  }

  /**
   *
   * @param {Number[]} [excludedEarnings = []]
   * @returns {Number}
   */
  getEarningsTotalAmt(excludedEarnings = []) {
    return this.earnings
      .filter(earning => !excludedEarnings.includes(earning.ixEarn))
      .reduce((accumulator, current) => +current.Amt + accumulator, 0);
  }

  /**
   *
   * @param {Number[]} [excludedLoans = []]
   * @returns {Number}
   */
  getLoansTotalAmt(excludedLoans = []) {
    return this.loans
      .filter(loan => !loan.isDeleted && !excludedLoans.includes(loan.ixAcc))
      .reduce((accumulator, current) => +current.Amt + accumulator, 0);
  }

  /**
   *
   * @param {Object} props
   * @param {Number[]} [props.excludedEarnings = []]
   * @param {Number[]} [props.excludedDeductions = []]
   * @param {Number[]} [props.excludedLoans = []]
   * @returns
   */
  getNetPay({
    excludedEarnings = [],
    excludedDeductions = [],
    excludedLoans = []
  }) {
    return (
      this.BscPay +
      this.getEarningsTotalAmt(excludedEarnings) -
      this.getDeductionsTotalAmt(excludedDeductions) -
      this.getLoansTotalAmt(excludedLoans)
    );
  }

  getTotalEmployerShare() {
    return this.deductions.reduce(
      (total, deduction) => total + deduction.ErAmt,
      0
    );
  }

  getTotalEmployeeShare() {
    return this.deductions.reduce(
      (total, deduction) => total + deduction.EeAmt,
      0
    );
  }

  /**
   *
   * @param {Number[]} [subList = []]
   * @returns {Number}
   */
  getSubValue(subList = []) {
    return subList.reduce((total, ixSub) => {
      const earning = this.earnings.find(earning => earning.ixEarn === ixSub);
      if (earning) return total + earning.Amt;

      const deduction = this.deductions.find(
        deduction => deduction.ixDed === ixSub
      );
      if (deduction) return total + deduction.EeAmt;

      return total;
    }, 0);
  }

  /**
   *
   * @param {Number[]} [subList = []]
   * @returns {Number}
   */
  getDeductionEmployerShare(subList = []) {
    return subList.reduce((total, ixDed) => {
      const deduction = this.deductions.find(
        deduction => deduction.ixDed === ixDed
      );

      return deduction ? total + deduction.ErAmt : total;
    }, 0);
  }

  /**
   *
   * @param {Number} ixDate
   * @returns {Leave[]}
   */
  combinedLeaves(ixDate = 0) {
    if (!ixDate) return [...this.leaves, ...this.linkedLeaves];
    else
      return [...this.leaves, ...this.linkedLeaves].filter(
        leave => leave.ixDate === ixDate
      );
  }

  getVariables() {
    return {
      msal: this.MSal,
      bscpay: this.BscPay,
      Rate1: this.Rate1,
      Rate2: this.Rate2,
      vHrs: this.vHrs,
      vPresent: this.vPresent,
      vAbsent: this.vAbsent,
      vLate: this.vLate,
      vUT: this.vUT,
      vOT: this.vOT,
      vOT_RH: this.vOT_RH,
      vOT_SH: this.vOT_SH,
      vOT_ND: this.vOT_ND,
      vOT_ND_RH: this.vOT_ND_RH,
      vOT_ND_SH: this.vOT_ND_SH,
      vOT_RD: this.vOT_RD,
      vOT_RD_OT: this.vOT_RD_OT,
      vOT_RD_RH: this.vOT_RD_RH,
      vOT_RD_SH: this.vOT_RD_SH,
      vND: this.vND,
      vND_RH: this.vND_RH,
      vND_SH: this.vND_SH,
      vND_RD: this.vND_RD,
      vND_RD_RH: this.vND_RD_RH,
      vND_RD_SH: this.vND_RD_SH,
      vOB: this.vOB,
      vRH: this.vRH,
      vSH: this.vSH,
      vSL: this.vSL,
      vVL: this.vVL,
      vPL: this.vPL,
      vML: this.vML,
      var1: this.var1,
      var2: this.var2,
      var3: this.var3,
      var4: this.var4,
      var5: this.var5,
      var6: this.var6,
      var7: this.var7,
      var8: this.var8,
      var9: this.var9,
      D0: this.D0,
      D1: this.D1,
      D2: this.D2,
      D3: this.D3,
      D4: this.D4,
      D5: this.D5,
      D6: this.D6,
      DT: this.DT
    };
  }

  addLoan({
    ixEmp,
    sEmp,
    ixReqBalSrc,
    ixAcc,
    sAcc,
    Amt,
    Remarks,
    ixSub1,
    ixSub2,
    ixSub3,
    ixSub4,
    ixSub5,
    sSubAcc,
    sSubAcc2,
    sSubAcc3,
    sSubAcc4,
    sSubAcc5,
    amtBal,
    ixBrch
  }) {
    this.loans = [
      ...this.loans,
      new Loan({
        ixEmp,
        sEmp,
        ixAcc,
        sAcc,
        Amt,
        Remarks,
        ixReqBalSrc,
        ixSub1,
        ixSub2,
        ixSub3,
        ixSub4,
        ixSub5,
        sSubAcc,
        sSubAcc2,
        sSubAcc3,
        sSubAcc4,
        sSubAcc5,
        amtBal,
        ixBrch
      })
    ];
  }

  /**
   *
   * @param {String} field
   * @param {any} defaultValue
   */
  getPrField(field, defaultValue) {
    return getPropValue(
      this.prInfo,
      field,
      defaultValue !== undefined ? defaultValue : ''
    );
  }

  /**
   *
   * @param {String} field
   * @param {any} defaultValue
   */
  getField(field, defaultValue) {
    return this.info?.[field] || defaultValue || '';
  }

  updateLoan(ixAcc, ixReqBalSrc, data = {}) {
    const index = this.loans.findIndex(
      loan => loan.ixAcc === ixAcc && loan.ixReqBalSrc === ixReqBalSrc
    );
    if (index === -1) return;

    this.loans[index].update(data);
  }

  addDeduction({
    ixDed = '',
    sDed = '',
    EeAmt = 0,
    ErAmt = 0,
    Qty = 0,
    Rate = 0,
    Particulars = ''
  }) {
    if (!ixDed) return;

    this.deductions = [
      ...this.deductions,
      new Deduction({
        ixEmp: this.ixEmp,
        sEmp: this.sEmp,
        ixDed,
        sDed,
        EeAmt,
        ErAmt,
        Qty,
        Rate,
        Particulars
      })
    ];
  }

  updateDeduction(ixDed, data = {}) {
    const index = this.deductions.findIndex(
      deduction => deduction.ixDed === ixDed
    );
    if (index === -1) return;

    this.deductions[index].update(data);
  }

  /**
   *
   * @param {Number} ixDate
   * @param {Log} update
   */
  updateLog(ixDate, update) {
    const log = this.logs.find(item => item.ixDate === ixDate);

    if (log) {
      log.update(update);
    }
  }

  addEarning({
    ixEarn = '',
    sEarn = '',
    Amt = 0,
    Qty = 0,
    Rate = 0,
    Particulars = ''
  }) {
    if (!ixEarn) return;

    this.earnings = [
      ...this.earnings,
      new Earning({
        ixEmp: this.ixEmp,
        sEmp: this.sEmp,
        ixEarn,
        sEarn,
        Amt,
        Qty,
        Rate,
        Particulars
      })
    ];
  }

  /**
   *
   * @param {Log[]} logs
   */
  setLogs(logs = []) {
    this.logs = logs;
    return this;
  }

  updateEarning(ixEarn, data = {}) {
    const index = this.earnings.findIndex(earning => earning.ixEarn === ixEarn);
    if (index === -1) return;

    this.earnings[index].update(data);
  }

  /**
   *
   * @param {number|string} loanId
   * @returns {boolean}
   */
  deleteLoan(loanId) {
    if (isNumber(loanId)) {
      const loan = this.loans.find(item => item.id === loanId);
      if (loan) {
        loan.update({ isDeleted: true });
      }
    } else {
      this.loans = this.loans.filter(loan => loan.id !== loanId);
    }
    return true;
  }

  /**
   * @param {Number} id id of deduction
   * @param {Request} request
   * @param {notify} notify
   * @returns {Promise<Boolean>} returns true if deduction is successfully removed
   */
  async deleteDeduction(id, request, notify) {
    const deduction = this.deductions.find(ded => ded.id === id);

    if (!deduction) {
      notify.error('Deduction not found.');
      return false;
    }

    if (!isNumber(deduction.id)) {
      this.deductions = this.deductions.filter(
        item => item.id !== deduction.id
      );
      notify.success('Deduction removed successfully.');
      return true;
    }

    const res = await request.delete(trans.payroll.deleteDeduction, {
      ixPR: this.ixPR,
      ixEmp: this.ixEmp,
      id: deduction.id
    });

    if (res.success) {
      this.deductions = this.deductions.filter(
        item => item.id !== deduction.id
      );
      notify.success('Deduction removed successfully.');
      return true;
    }

    notify.error('Failed to remove deduction.');
    return false;
  }

  /**
   * @param {Number} id id of earning
   * @param {Request} request
   * @param {notify} notify
   * @returns {Promise<Boolean>} returns true if earning is successfully removed
   */
  async deleteEarning(id, request, notify) {
    const earning = this.earnings.find(earn => earn.id === id);

    if (!earning) {
      notify.error('Earning not found.');
      return false;
    }

    if (!isNumber(earning.id)) {
      this.earnings = this.earnings.filter(item => item.id !== earning.id);
      notify.success('Earning removed successfully.');
      return true;
    }

    const res = await request.delete(trans.payroll.deleteEarning, {
      ixPR: this.ixPR,
      ixEmp: this.ixEmp,
      id: earning.id
    });

    if (res.success) {
      this.earnings = this.earnings.filter(item => item.id !== earning.id);
      notify.success('Earning removed successfully.');
      return true;
    }

    notify.error('Failed to remove earning.');
    return false;
  }

  /**
   *
   * @param {Request} request
   * @param {notify} notify
   * @returns {Promise<Boolean>} returns true if successfully cleared all deductions else false
   */
  async clearDeductions(request, notify) {
    this.deductions = this.deductions.filter(deduction =>
      isNumber(deduction.id)
    );

    const requests = this.deductions.map(deduction => ({
      url: trans.payroll.deleteDeduction,
      method: 'DELETE',
      data: {
        ixPR: this.ixPR,
        ixEmp: this.ixEmp,
        id: deduction.id
      }
    }));

    const results = await request.multiFetch(requests);

    let errorEncountered = false,
      indexCounter = 0;

    results.forEach(result => {
      if (result.success) {
        this.deductions.splice(indexCounter, 1);
      } else {
        notify.error(
          'Failed to remove deduction: ' + this.deductions[indexCounter].sDed
        );
        errorEncountered = true;
        indexCounter++;
      }
    });

    return !errorEncountered;
  }

  /**
   *
   * @param {Request} request
   * @param {notify} notify
   * @returns {Promise<Boolean>} returns true if successfully cleared all earnings else false
   */
  async clearEarnings(request, notify) {
    this.earnings = this.earnings.filter(earning => isNumber(earning.id));

    const requests = this.earnings.map(earning => ({
      url: trans.payroll.deleteEarning,
      method: 'DELETE',
      data: {
        ixPR: this.ixPR,
        ixEmp: this.ixEmp,
        id: earning.id
      }
    }));

    const results = await request.multiFetch(requests);

    let errorEncountered = false,
      indexCounter = 0;

    results.forEach(result => {
      if (result.success) {
        this.earnings.splice(indexCounter, 1);
      } else {
        notify.error(
          'Failed to remove earning: ' + this.earnings[indexCounter].sDed
        );
        errorEncountered = true;
        indexCounter++;
      }
    });

    return !errorEncountered;
  }

  /**
   *
   * @param {notification} notification
   */
  pushNotification(notification) {
    this.notifications.push(notification);
  }

  getDetails(props = []) {
    if (props.length > 0) {
      const res = {};

      props.forEach(prop => {
        res[prop] = this[prop];
      });

      return res;
    }

    return {
      ixEmp: this.ixEmp,
      sEmp: this.sEmp,
      MSal: this.MSal,
      BscPay: this.BscPay,
      Rate1: this.Rate1,
      Rate2: this.Rate2,
      Remarks: this.Remarks,

      ixBrch: this.ixBrch,
      ixDept: this.ixDept,
      ixDept2: this.ixDept2,

      vHrs: this.vHrs,
      vPresent: this.vPresent,
      vAbsent: this.vAbsent,
      vLate: this.vLate,
      vUT: this.vUT,

      vOT: this.vOT,
      vOT_RH: this.vOT_RH,
      vOT_SH: this.vOT_SH,
      vOT_ND: this.vOT_ND,
      vOT_ND_RH: this.vOT_ND_RH,
      vOT_ND_SH: this.vOT_ND_SH,
      vOT_RD: this.vOT_RD,
      vOT_RD_OT: this.vOT_RD_OT,
      vOT_RD_RH: this.vOT_RD_RH,
      vOT_RD_SH: this.vOT_RD_SH,

      vND: this.vND,
      vND_RH: this.vND_RH,
      vND_SH: this.vND_SH,
      vND_RD: this.vND_RD,
      vND_RD_RH: this.vND_RD_RH,
      vND_RD_SH: this.vND_RD_SH,

      vOB: this.vOB,
      vRH: this.vRH,
      vSH: this.vSH,
      vSL: this.vSL,
      vVL: this.vVL,
      vPL: this.vPL,
      vML: this.vML,

      var1: this.var1,
      var2: this.var2,
      var3: this.var3,
      var4: this.var4,
      var5: this.var5,
      var6: this.var6,
      var7: this.var7,
      var8: this.var8,
      var9: this.var9,

      D0: this.D0,
      D1: this.D1,
      D2: this.D2,
      D3: this.D3,
      D4: this.D4,
      D5: this.D5,
      D6: this.D6,
      DT: this.DT
    };
  }
}

export default Employee;
