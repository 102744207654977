import React, { useState } from 'react';
import AddressReview from './AddressReview';

function restructureData(data = []) {
  const res = [];

  for (const row of data) {
    const provinceIndex = res.findIndex(
      item => item.province === row.stateProv
    );

    if (provinceIndex === -1) {
      res.push({
        province: row.stateProv,
        cities: [
          {
            city: row.cityMuni,
            barangays: [{ barangay: row.townBar }]
          }
        ]
      });
    } else {
      const cityIndex = res[provinceIndex].cities.findIndex(
        city => city.city === row.cityMuni
      );

      if (cityIndex === -1) {
        res[provinceIndex].cities.push({
          city: row.cityMuni,
          barangays: [{ barangay: row.townBar }]
        });
      } else {
        res[provinceIndex].cities[cityIndex].barangays.push({
          barangay: row.townBar
        });
      }
    }
  }

  return res;
}

const WordArrangeModal = ({ dataUse, onSubmitAddresses, address }) => {
  const restructuredAddress = restructureData(dataUse);
  const [del, setDelimiter] = useState(',');
  const [wordsText, setWordsText] = useState('');

  const delText = wordsText;
  const allCapsText = delText.toUpperCase();

  let delWords = allCapsText.split(del || ' ').map(word => word.trim());

  const formatWithDashes = words =>
    words.map(word => word.replace(/\s+/g, '-'));

  const restoreWhiteSpace = words => words.map(word => word.replace(/-/g, ' '));

  const scanAndCompare = (words, list) => {
    const matchedWords = [];
    let remainingWords = [...words];

    remainingWords = remainingWords.filter(word => {
      if (list.includes(word.toUpperCase())) {
        matchedWords.push(word);
        return false;
      }
      return true;
    });

    if (matchedWords.length === 0 && remainingWords.length > 0) {
      const dashedWords = formatWithDashes(remainingWords);
      remainingWords = dashedWords.filter(word => {
        if (list.includes(word.toUpperCase())) {
          matchedWords.push(word);
          return false;
        }
        return true;
      });
    }

    const cleanedRemainingWords = restoreWhiteSpace(remainingWords);

    return { matchedWords, remainingWords: cleanedRemainingWords };
  };

  const provinceList = restructuredAddress.map(prov => prov.province);

  const {
    matchedWords: matchedProvinces,
    remainingWords: remainingAfterProvince
  } = scanAndCompare(delWords, provinceList);
  delWords = remainingAfterProvince;

  const provIndex = restructuredAddress.findIndex(
    prov => prov.province === `${matchedProvinces}`
  );

  const cities = provIndex !== -1 ? restructuredAddress[provIndex].cities : [];
  const townCities = cities.map(city => city.city);

  const {
    matchedWords: matchedCities,
    remainingWords: remainingAfterCities
  } = scanAndCompare(delWords, townCities);
  delWords = remainingAfterCities;

  const cityIndex = cities.findIndex(city => city.city === `${matchedCities}`);
  const cityBarangays = cityIndex !== -1 ? cities[cityIndex].barangays : [];

  const barangays = cityBarangays.map(barangay => barangay.barangay);

  const evaluateBarangay = (remainingWords, barangays) => {
    const formatWithDashes = word => word.replace(/\s+/g, '-');
    const addPob = word => `${word} (POB.)`;

    const spreadDashes = word => {
      const parts = word.split(/\s+/);
      const results = new Set();

      for (let i = 0; i < parts.length; i++) {
        for (let j = i + 1; j <= parts.length; j++) {
          const slice = parts.slice(i, j).join(' ');
          results.add(slice);
          results.add(formatWithDashes(slice));
        }
      }

      return Array.from(results);
    };

    const generateCombinations = words => {
      const combinations = new Set();
      for (let i = 0; i < words.length; i++) {
        for (let j = i + 1; j <= words.length; j++) {
          const slicedWords = words.slice(i, j).join(' ');

          combinations.add(slicedWords);
          combinations.add(formatWithDashes(slicedWords));
          combinations.add(addPob(slicedWords));
          combinations.add(formatWithDashes(addPob(slicedWords)));
          spreadDashes(slicedWords).forEach(combination =>
            combinations.add(combination)
          );
        }
      }
      return Array.from(combinations);
    };

    const wordArray = remainingWords.flatMap(word => word.split(/\s+/));
    const combinations = generateCombinations(wordArray);

    let matchedBarangay = '';
    let remainingAfterBarangays = [...remainingWords];

    for (const barangay of barangays) {
      for (const combination of combinations) {
        if (barangay.toUpperCase() === combination.toUpperCase()) {
          matchedBarangay = combination;

          const wordsToRemove = combination.split(/[\s\-]+/);
          remainingAfterBarangays = remainingAfterBarangays.map(text =>
            wordsToRemove.reduce(
              (updatedText, word) =>
                updatedText
                  .replace(new RegExp(`\\b${word}\\b`, 'gi'), '')
                  .trim(),
              text
            )
          );

          break;
        }
      }
      if (matchedBarangay) break;
    }

    return {
      matchedBarangay,
      remainingAfterBarangays: remainingAfterBarangays.filter(
        text => text.length > 0
      )
    };
  };

  const { matchedBarangay, remainingAfterBarangays } = evaluateBarangay(
    delWords,
    barangays
  );

  function scanAndRemoveNumbers(arr) {
    const numberRegex = /\b\d{4,}\b/;
    const ZIP = [];

    arr = arr.filter(item => {
      if (numberRegex.test(item)) {
        ZIP.push(item.match(numberRegex)[0]);
        return false;
      }
      return true;
    });

    return { updatedArray: arr, ZIP };
  }

  const { updatedArray, ZIP } = scanAndRemoveNumbers(remainingAfterBarangays);

  const leftWords = updatedArray.map(item => {
    ZIP.forEach(number => {
      item = item.replace(number, '').trim();
    });
    return item;
  });

  const handleArrangeWord = value => {
    setWordsText(value);
  };

  const handleDelimiter = value => {
    setDelimiter(value);
  };

  const handleInputAddresses = data => {
    onSubmitAddresses(data);
  };

  return (
    <>
      <AddressReview
        restructuredAddress={restructuredAddress}
        matchedProvinces={matchedProvinces}
        matchedCities={matchedCities}
        matchedBarangay={matchedBarangay}
        zip={ZIP}
        leftWords={leftWords}
        onSubmitArrangeWord={handleArrangeWord}
        onSubmitDelimeter={handleDelimiter}
        onSubmitAddresses={handleInputAddresses}
        address={address}
      />
    </>
  );
};

export default WordArrangeModal;
