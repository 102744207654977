import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

const Bool = ({ data, onChangeField }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          disabled={data?.disabled}
          checked={Boolean(data?.value)}
          onChange={e =>
            onChangeField({
              target: {
                name: data.fld,
                value: e.target.checked
              }
            })
          }
        />
      }
      label={data.caption}
    />
  );
};

export default Bool;
