import React, { useMemo } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  LinearProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Divider,
  Chip,
  TextField,
  MenuItem,
  Zoom,
  Button
} from '@material-ui/core';
import moment from 'moment';
import { getsJStatus, getStatusColor } from 'src/helper';
import { red, yellow, blue } from '@material-ui/core/colors';
import numeral from 'numeral';
import { LEAVES_W_BAL, SIL_TYPE_DESCRIPTION } from 'src/constants';

const useStyles = makeStyles(theme => ({
  totals: {
    fontWeight: 900
  },
  root: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.background.default
    }
  },
  red: {
    background: red[100],
    '& .MuiTableCell-body': {
      color: theme.palette.getContrastText(red[100])
    }
  },
  yellow: {
    background: yellow[100],
    '& .MuiTableCell-body': {
      color: theme.palette.getContrastText(yellow[100])
    }
  },
  blue: {
    background: blue[100],
    '& .MuiTableCell-body': {
      color: theme.palette.getContrastText(blue[100])
    }
  }
}));

function formatDateToStr(date) {
  const dateStr = date.toString();
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  const day = dateStr.slice(6);

  return `${month}/${day}/${year}`;
}

const SILLedgerContent = ({
  items = [],
  totals = {
    qtyIN: 0,
    qtyOUT: 0,
    bal: 0
  }
}) => {
  const classes = useStyles();

  const getJStatusColor = status => {
    if (status === 0) return classes.blue;
    else if (status === 2) return classes.red;
    else if (status === 3) return classes.red;
    else return classes.root;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Particulars</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Leave Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Remarks</TableCell>
            <TableCell align="right">In</TableCell>
            <TableCell align="right">Out</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 ? (
            <TableRow>
              <TableCell colSpan="100%" align="center">
                No data to show.
              </TableCell>
            </TableRow>
          ) : (
            <>
              {items.map((item, i) => (
                <TableRow className={getJStatusColor(item.jStatus)} key={i}>
                  <TableCell>
                    {moment(item.jDate).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{item.Name}</TableCell>
                  <TableCell>{item.Particulars}</TableCell>
                  <TableCell>{item.Remarks}</TableCell>
                  <TableCell>{formatDateToStr(item.ixDate)}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      style={{
                        backgroundColor: getStatusColor(item.jStatus),
                        color: '#FFF'
                      }}
                      label={<strong>{getsJStatus(item.jStatus)}</strong>}
                    />
                  </TableCell>
                  <TableCell>{item['t.Remarks']}</TableCell>
                  <TableCell align="right">
                    {item.qtyIN ? numeral(item.qtyIN).format('0.00') : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {item.qtyOUT ? numeral(item.qtyOUT).format('0.00') : '-'}
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {item.bal ? numeral(item.bal).format('0.00') : '-'}
                    </strong>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="right" className={classes.totals}>
                  Total
                </TableCell>
                <TableCell align="right" className={classes.totals}>
                  {totals.qtyIN ? numeral(totals.qtyIN).format('0.00') : '-'}
                </TableCell>
                <TableCell align="right" className={classes.totals}>
                  {totals.qtyOUT ? numeral(totals.qtyOUT).format('0.00') : '-'}
                </TableCell>
                <TableCell align="right" className={classes.totals}>
                  {totals.bal ? numeral(totals.bal).format('0.00') : '-'}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {VoidFunction} props.onClose
 * @param {(ixSILType: number) => void} props.changeIxSILType
 * @param {Array} props.data
 * @param {number} props.ixSILType
 * @param {boolean} props.isLoading
 * @returns
 */
const SILLedger = ({
  open,
  onClose,
  data,
  ixSILType,
  changeIxSILType,
  isLoading
}) => {
  const { totals, ledger } = useMemo(() => {
    const totals = { qtyIN: 0, qtyOUT: 0, bal: 0 };
    const ledger = [];

    for (const item of data) {
      const qtyIN = item.qtyIN / 100;
      const qtyOUT = item.qtyOUT / 100;
      const bal = item.bal / 100;

      totals.qtyIN += qtyIN;
      totals.qtyOUT += qtyOUT;
      totals.bal = bal;

      ledger.push({ ...item, qtyIN, qtyOUT, bal });
    }

    return { totals, ledger };
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
      fullWidth
    >
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Leave Credits Ledger</Typography>
          <Box display="flex" alignItems="center" gridGap={8}>
            <TextField
              select
              variant="outlined"
              size="small"
              value={ixSILType}
              onChange={e => changeIxSILType(e.target.value)}
            >
              {LEAVES_W_BAL.map(ixSILType => (
                <MenuItem key={ixSILType} value={ixSILType}>
                  {SIL_TYPE_DESCRIPTION[ixSILType]}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      {isLoading && <LinearProgress style={{ height: 2 }} />}
      <DialogContent style={{ padding: 0 }}>
        <SILLedgerContent items={ledger} totals={totals} />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(SILLedger);
