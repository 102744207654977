import { createSlice } from '@reduxjs/toolkit';
import Request from 'src/helpers/Request';
import { jsked, trans } from 'src/lib/endpoints';

const INITIAL_STATE = {
  JEVno: {},
  info: {},
  check: {},
  prodCatalog: {},
  reports: {},
  frontEnd: {},
  brchAsBiz: {},
  setup: {},
  transactions: [],
  jskedGroups: [],
  tax: {},
  brch: {},
  budget: {},
  inv: {},
  sidebarState: {},
  wf: {},
  prodSubLink: {},
  users: [],
  units: ['Pc/s', 'Kg/s', 'Liter/s', 'Bale/s'],
  cs_units: ['CS', 'Box/es', 'Case/s'],
  sc_units: ['SC', 'Pack/s', 'Box/es'],
  pr: {},
  priv: {},
  userBranchLimits: [],
  dashboards: [],
  ixChkMode: 0,
  ru_note: '',
  kvs: {}
};

const removeDuplicates = items => {
  return [...new Set(items)];
};

const slice = createSlice({
  name: 'bizMeta',
  initialState: INITIAL_STATE,
  reducers: {
    loadBizSettings(state, action) {
      const { biz, trans, wf, users, jskedGroups, userLimits } = action.payload;

      if (biz.success) {
        state.prodCatalog = biz?.data['prod-catalog'] || {};
        state.info = biz?.data.info || {};
        state.ixChkMode = biz?.data.ixChkMode ?? 0;
        state.reports = biz?.data?.reports || {};
        state.frontEnd = biz?.data?.['front-end'] || {};
        state.setup = biz?.data?.setup || {};
        state.tax = biz?.data?.tax || {};
        state.pr = biz?.data?.pr || {};
        state.priv = biz?.data?.priv || {};
        state.brch = biz?.data?.brch || {};
        state.inv = biz?.data?.inv || {};
        state.prodSubLink = biz?.data?.prodSubLink || {};
        state.budget = biz?.data?.budget || {};
        state.dashboards = biz?.data?.dashboards || [];
        state.JEVno = biz?.data?.JEVno || {};
        state.brchAsBiz = biz?.data?.['brch-as-biz'] ?? {};
        state.check = biz?.data?.check ?? {};
        state.kvs = biz?.data?.kvs ?? {};
      }

      state.userBranchLimits = userLimits;

      if (trans.success) state.transactions = trans?.data || [];

      if (wf.success) state.wf = wf?.data || {};

      if (users.success) state.users = users.data || [];

      if (jskedGroups.success) state.jskedGroups = jskedGroups.data;
    },

    setSideBarState(state, action) {
      return {
        ...state,
        sidebarState: {
          ...state.sidebarState,
          ...action.payload
        }
      };
    },

    addUnit(state, action) {
      state.units = removeDuplicates([...state.units, action.payload]);
    },
    addCSUnit(state, action) {
      state.cs_units = removeDuplicates([...state.cs_units, action.payload]);
    },
    addSCUnit(state, action) {
      state.sc_units = removeDuplicates([...state.sc_units, action.payload]);
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const fetchBizSettings = async (base_url, token) => {
  const config = {
    headers: {
      'x-access-tokens': token,
      'Content-Type': 'application/json'
    }
  };

  const request = new Request(base_url, config);

  const [biz, transactions, wf, users, jskedGroups] = await Promise.all([
    request.get('/lib/biz'),
    request.get('/trans/jcd/by/user'),
    request.get('/trans/wf/meta'),
    request.get('/users'),
    request.get(jsked.getJSkedGroups)
  ]);

  const bizSettings = {
    biz,
    trans: transactions,
    wf,
    users,
    jskedGroups
  };

  if (users.success && biz.success && biz.data?.brch?.mode) {
    const userLimits = await request.get(
      trans.allUserLimits(biz?.data?.brch?.ixBrchSubType || 0)
    );

    if (userLimits.success)
      bizSettings['userLimits'] = users.data.map(user => ({
        ...user,
        limits: userLimits.data.filter(limit => limit.ixUser === user.ixUser)
      }));
  }

  return bizSettings;
};

const bizMetaReducer = slice.reducer;
export const {
  loadBizSettings,
  loadBizTransactions,
  setSideBarState,
  loadBizWFMeta,
  loadBizUsers,
  loadBrchSettings,
  loadProdSubLink,
  addUnit,
  addCSUnit,
  addSCUnit,
  clear,
  restore
} = slice.actions;
export default bizMetaReducer;
