import React from 'react';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { DataTable } from 'src/components';
import { Delete } from '@material-ui/icons';

const getDefaultValue = type => {
  if (type === 'date') return moment().format('YYYY-MM-DD');
  return '';
};

const TableView = ({ data, onChangeField }) => {
  const value = data?.value || [];
  const columns = data?.columns || [];

  const handleAddItem = () => {
    const items = [...value];
    const row = {
      id: uuid()
    };

    for (const column of columns) {
      row[column.field] = getDefaultValue(column?.type);
    }

    items.push(row);

    onChangeField({
      target: {
        name: data.fld,
        value: items
      }
    });
  };

  const handleEditItem = row => {
    const items = [...value];
    const index = value.findIndex(item => item.id === row.id);

    items[index][row.field] = row.value;

    onChangeField({
      target: {
        name: data.fld,
        value: items
      }
    });
  };

  const handleDeleteItem = id => {
    const items = [...value];
    onChangeField({
      target: {
        name: data.fld,
        value: items.filter(item => item.id !== id)
      }
    });
  };

  return (
    <Box>
      {Boolean(data?.caption) && (
        <Box mb={1}>
          <Typography variant="h4" color="primary">
            {data.caption}
          </Typography>
        </Box>
      )}
      <DataTable
        columns={[
          ...columns,
          {
            field: 'action',
            headerAlign: 'center',
            headerName: 'Action',
            width: 100,
            align: 'center',
            renderCell: ({ row }) => (
              <IconButton
                color="primary"
                onClick={() => handleDeleteItem(row.id)}
                size="small"
              >
                <Delete />
              </IconButton>
            )
          }
        ]}
        data={value}
        onCellEdit={handleEditItem}
        rootContainerProps={{ border: '1px solid #C4C4C4' }}
        {...(data?.tableProps || {})}
      />
      <Box mt={2}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleAddItem}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default TableView;
