import React from 'react';
import { Box, Grid, Typography, Link } from '@material-ui/core';
import { useDynamicPages } from '../../DynamicPageProvider';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const iconMap = {
  email: EmailIcon,
  phone: PhoneIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon
};

const Socials = () => {
  const { pageDetails } = useDynamicPages();

  const socials = pageDetails?.jsonSettings?.socials;

  if (!socials || !socials.show) {
    return null;
  }

  const parseTextWithLinks = (text, urls) => {
    const lines = text.split('\n');

    return lines.map((line, lineIndex) => (
      <span
        key={lineIndex}
        style={{ display: 'block', marginBottom: '8px' }} // Add space between lines
      >
        {line.split(/<<([^>>]+)>>/g).map((part, index) => {
          const urlData = urls?.find(url => url.linkText === part);
          if (urlData) {
            const IconComponent = iconMap[urlData.icon];
            return (
              <span
                key={index}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginRight: '8px'
                }}
              >
                {IconComponent && (
                  <IconComponent
                    style={{
                      fontSize: urlData.icon_size
                        ? `${urlData.icon_size}em`
                        : '1em',
                      marginRight: '4px'
                    }}
                  />
                )}
                <Link
                  href={urlData.link}
                  style={{
                    color: urlData.text_color || 'inherit',
                    textDecoration: urlData.underline ? 'underline' : 'none'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {urlData.linkText}
                </Link>
              </span>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </span>
    ));
  };

  return (
    <Box sx={{ padding: '10%', maxWidth: '1500px', margin: '0 auto' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              textAlign: 'center',
              fontSize: socials?.fontSize || '1em',
              fontWeight: 'bold',
              color: socials.txt1 || 'inherit'
            }}
          >
            {socials.primaryText}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: '15px' }}>
            {socials.items.map(item => (
              <Box key={item.id} sx={{ marginBottom: '16px' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: 'bold',
                    fontSize: item?.p_fontSize || '1em',
                    marginBottom: '-0.1em',
                    color: socials.txt1 || 'inherit'
                  }}
                >
                  {item.primaryText}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontSize: item?.s_fontSize || '1em',
                    color: socials.txt2 || 'inherit',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {parseTextWithLinks(item.secondaryText, item.url)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: '16px', textAlign: 'center' }}>
            <img
              src={
                socials?.img
                  ? `/api/pages/${pageDetails?.ixPage}/images/${socials?.img}`
                  : ''
              }
              alt={socials.imageAlt || 'Social illustration'}
              style={{
                width: '100%',
                maxWidth: '400px',
                height: 'auto',
                borderRadius: '8px'
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Socials;
