import React from 'react';
import {
  Dialog,
  Box,
  CircularProgress,
  Typography,
  Zoom
} from '@material-ui/core';

const LoadingDialog = ({ open = false }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" TransitionComponent={Zoom}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={300}
        gridGap={16}
      >
        <CircularProgress size={50} />
        <Typography variant="h4" color="primary">
          Loading...
        </Typography>
      </Box>
    </Dialog>
  );
};

export default LoadingDialog;
