import {
  Box,
  TextField,
  Button,
  MenuItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

/**
 * @param {Object} props - Component properties
 * @param {Array<string>} props.matchedProvinces - List of matched provinces based on the search criteria.
 * @param {Array<string>} props.matchedCities - List of matched cities/municipalities based on the search criteria.
 * @param {Array<string>} props.matchedBarangay - List of matched barangays based on the search criteria.
 * @param {string} props.zip - ZIP code associated with the selected address.
 * @param {Array<string>} props.leftWords - List of words that are left or unused in the address restructuring process.
 * @param {Function} props.onSubmitArrangeWord - Callback function to handle the arrangement of words in the address.
 * @param {Array<Object>} props.restructuredAddress - List of restructured address objects.
 * @param {Function} props.onSubmitDelimeter - Callback function to handle the submission of delimiters in the address.
 * @param {Function} props.onSubmitAddresses - Callback function to handle the submission of complete addresses.
 */

const AddressReview = ({
  matchedProvinces,
  matchedCities,
  matchedBarangay,
  zip,
  leftWords,
  onSubmitArrangeWord,
  restructuredAddress,
  onSubmitDelimeter,
  onSubmitAddresses,
  address
}) => {
  const [inputText, setInputText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [delimiter, setDelimiter] = useState(',');
  const [errorFields, setErrorFields] = useState({});

  const [addProv, setProv] = useState('');
  const [addMuniCity, setMuniCity] = useState('');
  const [addBrgy, setBrgy] = useState('');
  const [leftWordsInput, setLeftWordsInput] = useState(leftWords || '');
  const [zipInput, setZipInput] = useState(zip || '');
  const [showHelperText, setShowHelperText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = event => {
    const text = event.target.value.toUpperCase();
    setInputText(text);
    onSubmitArrangeWord(text);
    if (text) {
      setShowHelperText(true);
    }
  };

  /**
   *
   * @param {React.ClipboardEvent<HTMLInputElement>} event
   */

  const handleInputDilimeter = event => {
    const text = event.target.value;
    setDelimiter(text);
    onSubmitDelimeter(text);
  };

  const validateRequiredFields = () => {
    const errors = {};
    if (!addProv) errors.province = true;
    if (!addMuniCity) errors.city = true;
    if (!addBrgy) errors.barangay = true;
    setErrorFields(errors);

    return Object.keys(errors).length === 0;
  };

  const handleInputAddresses = async () => {
    //I added !address here to prevent the modal from opening when the address is already set for creation of sub
    if (!validateRequiredFields() && !address) {
      setModalOpen(true);
      return;
    }

    setShowHelperText(false);
    setIsLoading(true);

    const addresses = {
      addProvince: addProv,
      addMuniCities: addMuniCity,
      addBarangays: addBrgy,
      addStreet: leftWordsInput,
      addZIP: zipInput
    };

    await new Promise(resolve => setTimeout(resolve, 1000));

    onSubmitAddresses(addresses);
    setIsLoading(false);
  };

  const handleInputCorrect = async () => {
    setIsLoading(true);

    setShowHelperText(false);

    const addresses = {
      addProvince: addProv,
      addMuniCities: addMuniCity,
      addBarangays: addBrgy,
      addStreet: leftWordsInput,
      addZIP: zipInput
    };

    await new Promise(resolve => setTimeout(resolve, 1000));

    onSubmitAddresses(addresses);
    closeModal();
    setIsLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setErrorFields({});
  };

  const provinces = restructuredAddress.map(prov => prov.province);
  const provIndex = restructuredAddress.findIndex(
    prov => prov.province === `${addProv}`
  );

  const cities = provIndex !== -1 ? restructuredAddress[provIndex].cities : [];
  const townCities = cities.map(city => city.city);

  const cityIndex = cities.findIndex(city => city.city === `${addMuniCity}`);
  const cityBarangays = cityIndex !== -1 ? cities[cityIndex].barangays : [];

  const barangays = cityBarangays.map(barangay => barangay.barangay);

  useEffect(() => {
    if (Array.isArray(matchedProvinces)) {
      setProv(matchedProvinces.join(' ') || '');
    } else if (typeof matchedProvinces === 'string') {
      setProv(matchedProvinces);
    } else {
      setProv('');
    }

    if (matchedProvinces) {
      setErrorFields(prev => {
        const updatedErrors = { ...prev };
        delete updatedErrors.province;
        return updatedErrors;
      });
    }
  }, [matchedProvinces]);

  useEffect(() => {
    if (Array.isArray(matchedCities)) {
      setMuniCity(matchedCities.join(' ') || '');
    } else if (typeof matchedCities === 'string') {
      setMuniCity(matchedCities);
    } else {
      setMuniCity('');
    }

    if (matchedCities) {
      setErrorFields(prev => {
        const updatedErrors = { ...prev };
        delete updatedErrors.city;
        return updatedErrors;
      });
    }
  }, [matchedCities]);

  useEffect(() => {
    if (Array.isArray(matchedBarangay)) {
      setBrgy(matchedBarangay.join(' ') || '');
    } else if (typeof matchedBarangay === 'string') {
      setBrgy(matchedBarangay);
    } else {
      setBrgy('');
    }

    if (matchedBarangay) {
      setErrorFields(prev => {
        const updatedErrors = { ...prev };
        delete updatedErrors.barangay;
        return updatedErrors;
      });
    }
  }, [matchedBarangay]);

  useEffect(() => {
    if (Array.isArray(leftWords)) {
      setLeftWordsInput(leftWords.join(' ') || '');
    } else if (typeof leftWords === 'string') {
      setLeftWordsInput(leftWords);
    } else {
      setLeftWordsInput('');
    }
  }, [leftWords]);

  useEffect(() => {
    if (Array.isArray(zip)) {
      setZipInput(zip.join(' ') || '');
    } else if (typeof zip === 'string') {
      setZipInput(zip);
    } else {
      setZipInput('');
    }
  }, [zip]);

  useEffect(() => {
    if (!address) return;
    setInputText(address);
    handleInputAddresses();
    onSubmitArrangeWord(address);
    setShowHelperText(true);
  }, [address, addProv, addMuniCity, addBrgy]);

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        label="Paste Here"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleInputAddresses();
          }
        }}
        helperText={
          showHelperText ? (
            <span style={{ color: 'red' }}>Press Enter to submit</span>
          ) : null
        }
      />
      {isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Dialog
        maxWidth="sm"
        fullWidth
        open={modalOpen}
        aria-labelledby="word-arrange-modal"
        aria-describedby="word-arrange-description"
      >
        <DialogTitle disableTypography>
          <Typography variant="h4" component="p" color="textSecondary">
            Verify Address
          </Typography>
          <Typography variant="subtitle2" component="p" color="textSecondary">
            Please verify the address before proceeding.
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ padding: 16 }}>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <TextField
                  maxRows={3}
                  multiline
                  fullWidth
                  variant="outlined"
                  label="Paste Here"
                  value={inputText}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Delimiter"
                  value={delimiter}
                  onChange={handleInputDilimeter}
                >
                  <MenuItem value=",">Comma (,)</MenuItem>
                  <MenuItem value=".">Period (.)</MenuItem>
                  <MenuItem value=";">Semicolon (;)</MenuItem>
                  <MenuItem value="|">Pipe (|)</MenuItem>
                  <MenuItem value="\t">Tab</MenuItem>
                  <MenuItem value=" ">Space</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Autocomplete
              options={provinces}
              getOptionLabel={option => option}
              value={addProv}
              onChange={(event, newValue) => {
                setProv(newValue);
                setErrorFields(prev => {
                  const updatedErrors = { ...prev };
                  if (newValue) delete updatedErrors.province;
                  return updatedErrors;
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Province"
                  variant="outlined"
                  fullWidth
                  error={!!errorFields.province}
                  helperText={errorFields.province && 'Province is required'}
                />
              )}
            />

            <Autocomplete
              options={townCities}
              getOptionLabel={option => option}
              value={addMuniCity}
              onChange={(event, newValue) => {
                setMuniCity(newValue);
                setErrorFields(prev => {
                  const updatedErrors = { ...prev };
                  if (newValue) delete updatedErrors.city;
                  return updatedErrors;
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Municipality/City"
                  variant="outlined"
                  fullWidth
                  error={!!errorFields.city}
                  helperText={
                    errorFields.city && 'Municipality/City is required'
                  }
                />
              )}
            />

            <Autocomplete
              options={barangays}
              getOptionLabel={option => option}
              value={addBrgy}
              onChange={(event, newValue) => {
                setBrgy(newValue);
                setErrorFields(prev => {
                  const updatedErrors = { ...prev };
                  if (newValue) delete updatedErrors.barangay;
                  return updatedErrors;
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Barangay"
                  variant="outlined"
                  fullWidth
                  error={!!errorFields.barangay}
                  helperText={errorFields.barangay && 'Barangay is required'}
                />
              )}
            />

            <TextField
              multiline
              maxRows={3}
              fullWidth
              variant="outlined"
              label="Street"
              value={leftWordsInput}
              onChange={e => setLeftWordsInput(e.target.value)}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="ZIP"
              value={zipInput}
              onChange={e => setZipInput(e.target.value)}
            />
            <TextField
              maxRows={3}
              multiline
              fullWidth
              variant="outlined"
              label="Arranged Text"
              value={`${matchedProvinces}, ${matchedCities}, ${matchedBarangay}, ${zip}, ${leftWords ||
                ' '}`}
              disabled
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleInputCorrect}
            disabled={
              !addProv ||
              !addMuniCity ||
              !addBrgy ||
              Object.keys(errorFields).length > 0 ||
              isLoading
            }
            startIcon={isLoading && <CircularProgress size={16} />}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressReview;
