import {
  Business,
  ListAlt,
  List,
  Tune,
  VerifiedUserOutlined,
  ShoppingCartOutlined,
  CategoryOutlined,
  BarChart,
  PeopleAlt,
  Store,
  Dashboard,
  TrendingUp,
  Layers,
  SettingsSystemDaydream,
  SupervisedUserCircle,
  GroupAdd,
  Event,
  Schedule,
  HomeWorkOutlined,
  SupervisorAccount
} from '@material-ui/icons';

export const navGroups = {
  inv: {
    whenPresent: ['gl', 'sl'],
    title: 'Inventory Reports',
    priv: 'inv'
  }
};

export const reportList = [
  {
    key: 'gl',
    title: 'General Ledger',
    href: '/app/reports/gl',
    priv: 'gl'
    // icon: ArrowRight
  },

  {
    key: 'sl',
    title: 'Subsidiary Ledger',
    href: '/app/reports/sl',
    priv: 'sl'
    // icon: ArrowRight
  },
  {
    key: 'sl2',
    title: 'Subsidiary Ledger 2',
    href: '/app/reports/sl2',
    priv: 'sl'
    // icon: ArrowRight
  },
  {
    key: 'sl3',
    title: 'Subsidiary Ledger 3',
    href: '/app/reports/sl3',
    priv: 'sl'
    // icon: ArrowRight
  },
  {
    key: 'sl-balances',
    href: '/app/reports/sl-balances-summary',
    title: 'SL Balances Summary',
    priv: 'sl'
  },
  {
    key: 'adv-sl-balances',
    href: '/app/reports/adv-sl-balances-summary',
    title: 'Advanced SL Balances Summary',
    priv: 'sl'
  },
  {
    group: 'inv',
    key: 'inv.sc',
    title: 'Stock Card',
    href: '/app/reports/sc',
    priv: 'inv.sc'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.stk-img',
    title: 'Stock Image',
    href: '/app/reports/stock-image',
    priv: 'inv.sc'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.order-sales',
    title: 'Sales Order Summary',
    href: '/app/reports/order-summary/so',
    priv: 'inv.order-sales'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.sales',
    title: 'Sales Summary',
    href: '/app/reports/sales-summary',
    priv: 'inv.sales'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.sales-ret',
    title: 'Sales Return Summary',
    href: '/app/reports/sales-return-summary',
    priv: 'inv.sales-ret'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.order-pur',
    title: 'Purchase Order Summary',
    href: '/app/reports/order-summary/po',
    priv: 'inv.order-pur'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.pur',
    title: 'Purchase Summary',
    href: '/app/reports/purchase-summary',
    priv: 'inv.pur'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.pur-ret',
    title: 'Purchase Return Summary',
    href: '/app/reports/purchase-return-summary',
    priv: 'inv.pur-ret'
    // icon: ArrowRight
  },

  {
    group: 'inv',
    key: 'inv.wh-transfer',
    title: 'Warehouse Transfer Summary',
    href: '/app/reports/wh-transfer-summary',
    priv: 'inv.wh-transfer'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.brch-transfer',
    title: 'Branch Transfer Summary',
    href: '/app/reports/br-transfer-summary',
    priv: 'inv.brch-transfer'
    // icon: ArrowRight
  },
  {
    group: 'inv',
    key: 'inv.ending',
    title: 'Ending Inventory',
    href: '/app/reports/ending-inventory',
    priv: 'inv.ending'
    // icon: ArrowRight
  },
  {
    key: 'je-pts',
    title: 'JE Points Reports',
    href: '/app/reports/jepts',
    priv: 'je-pts'
    // icon: ArrowRight
  },
  {
    key: 'je-pts-summary',
    title: 'JE Points Summary Reports',
    href: '/app/reports/jepts-summary',
    priv: 'je-pts'
    // icon: ArrowRight
  },
  {
    key: 'aging',
    title: 'Aging Report',
    href: '/app/reports/aging',
    priv: 'aging'
    // icon: ArrowRight
  },
  {
    key: 'chkreg',
    title: 'Check Register',
    href: '/app/reports/check-register',
    priv: 'check-register'
    // icon: ArrowRight
  },
  {
    key: 'mature-checks',
    title: 'Mature Checks Report',
    href: '/app/reports/mature-checks-report',
    priv: 'mature-checks'
    // icon: ArrowRight
  },
  {
    key: 'cashpo',
    title: 'Cash Position Report',
    href: '/app/reports/cash-position-report',
    priv: 'cashpo'
    // icon: ArrowRight
  },
  {
    key: 'spej',
    title: 'Special Journals',
    href: '/app/reports/special-journals',
    priv: 'spej'
    //   icon: ArrowRight
  },
  {
    key: 'fs',
    title: 'Financial Statement',
    href: '/app/reports/fs',
    priv: 'fs'
    // icon: ArrowRight
  },
  {
    key: 'gj',
    title: 'General Journal',
    href: '/app/reports/gj',
    priv: 'gl'
    // icon: ArrowRight
  },
  {
    key: 'imbal',
    title: 'Imbalanced Report',
    href: '/app/reports/imbalanced-report',
    priv: 'imbal'
    // icon: ArrowRight
  },
  {
    key: 'tax.view-parent',
    title: 'Tax Reports',
    priv: 'tax.view',
    // icon: ArrowRight,
    children: [
      {
        key: 'sos',
        title: 'Summary of Sales',
        href: '/app/reports/summary-of-sales',
        priv: 'tax.view'
        // icon: ArrowRight,
      },
      {
        key: 'sop',
        title: 'Summary of Purchases',
        href: '/app/reports/summary-of-purchases',
        priv: 'tax.view'
        // icon: ArrowRight,
      }
    ]
  },
  {
    key: 'pr-reports',
    title: 'Payroll',
    priv: 'hris.reports',
    children: [
      {
        key: 'emp-dashboard',
        title: 'Employee Dashboard',
        href: '/app/reports/payroll/employee-dashboard',
        priv: 'hris.reports'
      },
      {
        key: 'pr-annual',
        title: 'Annual Reports',
        href: '/app/reports/payroll/annual-reports',
        priv: 'hris.reports'
      },
      {
        key: '13th-month-pay',
        title: '13th Month Pay',
        href: '/app/reports/payroll/13th-month-pay',
        priv: 'hris.reports'
      }
    ]
  },
  {
    key: 'budget-reports',
    title: 'Budget',
    oneOfPriv: true,
    priv: ['budget.summary', 'budget.rapal', 'budget.raod', 'budget.ledger'],
    children: [
      {
        key: 'budget.ledger',
        title: 'Ledger',
        href: '/app/reports/budget/ledger',
        priv: 'budget.ledger'
      },
      {
        key: 'budget.summary',
        title: 'Summary',
        href: '/app/reports/budget/summary',
        priv: 'budget.summary'
      },
      {
        key: 'budget.saao',
        title: 'SAAO',
        href: '/app/reports/budget/saao',
        priv: 'budget.summary'
      },
      {
        key: 'budget.rapal',
        title: 'RAPAL',
        href: '/app/reports/budget/rapal',
        priv: 'budget.rapal'
      },
      {
        key: 'budget.raod',
        title: 'RAOD',
        href: '/app/reports/budget/raod',
        priv: 'budget.raod'
      }
    ]
  },
  {
    key: 'daloyWF',
    title: 'DaloyWF',
    priv: 'wf-admin',
    children: [
      {
        href: '/app/reports/wf-admin',
        title: 'Admin',
        key: 'wf-admin',
        priv: 'wf-admin'
      },
      {
        href: '/app/reports/daloy-board',
        title: 'DaloyBoard',
        key: 'daloy-board',
        priv: ['wf-admin', 'daloy-board']
      },
      {
        key: 'wf-stats',
        href: '/app/reports/wf-stats',
        title: 'Statistics',
        priv: 'wf-admin'
      }
    ]
  },
  {
    key: 'customrep',
    title: 'Custom Reports',
    href: '/app/reports/custom',
    priv: 'customrep'
    // icon: ArrowRight
  },
  {
    key: 'posting-summary',
    title: 'Posting Summary',
    href: '/app/reports/posting-summary',
    priv: 'posting-summary'
    // icon: ArrowRight
  }
];

export const setupList = [
  {
    key: 'biz',
    title: 'Business',
    href: '/app/setup/business/info',
    icon: Business,
    priv: 'biz'
  },
  {
    key: 'acc',
    title: 'Accounts',
    href: '/app/setup/accounts',
    icon: ListAlt,
    priv: 'acc'
  },
  {
    key: 'brch',
    title: 'Branches',
    icon: HomeWorkOutlined,
    priv: 'brch'
  },
  {
    key: 'emp',
    title: 'Employees',
    icon: SupervisorAccount,
    priv: 'hris.admin'
  },
  {
    key: 'subTypes',
    title: 'Sub-Types',
    href: '/app/setup/sub-acc/types',
    icon: List,
    priv: 'subTypes'
  },
  {
    key: 'subAcc',
    title: 'Sub-Accounts',
    href: '/app/setup/sub-accounts',
    icon: ListAlt,
    priv: 'subTypes'
  },
  {
    key: 'systemTypes',
    title: 'System Types',
    href: '/app/setup/system/types',
    icon: ListAlt,
    priv: 'systemTypes'
  },
  {
    key: 'jcd',
    title: 'Transaction Types',
    href: '/app/setup/transactions',
    icon: Tune,
    priv: 'jcd'
  },
  {
    key: 'prod-parent',
    title: 'Products',
    href: '/app/setup/products',
    icon: Store,
    priv: 'prod',
    children: [
      {
        key: 'prod',
        title: 'Product List',
        href: '/app/setup/products',
        icon: ShoppingCartOutlined,
        priv: 'prod'
      },
      {
        key: 'prod.cat',
        title: 'Product Categories',
        href: '/app/setup/product-categories',
        icon: CategoryOutlined,
        priv: 'prod.cat'
      },
      {
        key: 'prod.subcat',
        title: 'Product Sub-Categories',
        href: '/app/setup/product-sub-categories',
        icon: CategoryOutlined,
        priv: 'prod.cat'
      }
    ]
  },
  {
    key: 'customrep-setup',
    title: 'Custom Reports',
    href: '/app/setup/custom-reports',
    icon: BarChart,
    priv: 'customrep'
  },
  {
    key: 'dashboards',
    title: 'Dashboards',
    href: '/app/setup/dashboards',
    icon: Dashboard,
    priv: 'dashboards'
  },
  {
    key: 'fs',
    title: 'FS Layout',
    href: '/app/setup/fs-layout',
    icon: Layers,
    priv: 'fs'
  },
  {
    key: 'spej',
    title: 'Special Journals',
    href: '/app/setup/special-journal',
    icon: ListAlt,
    priv: 'spej'
  },
  {
    key: 'users',
    title: 'Users',
    href: '/app/setup/users',
    icon: PeopleAlt,
    priv: 'users'
  },
  {
    key: 'user-priv',
    title: 'Privileges',
    href: '/app/setup/privileges',
    icon: VerifiedUserOutlined,
    priv: 'users'
  },
  {
    key: 'targets',
    title: 'Targets',
    href: '/app/setup/targets',
    icon: TrendingUp,
    priv: 'adv'
  },
  {
    key: 'portal',
    title: 'Portal',
    href: '/app/setup/portal',
    icon: SupervisedUserCircle,
    priv: 'portal'
  },
  {
    key: 'portal-admin',
    title: 'Portal Admin',
    href: '/app/setup/portal-admin',
    icon: GroupAdd,
    priv: 'hris.portal-admin'
  },
  {
    key: 'calendar',
    title: 'Calendar',
    href: '/app/setup/calendar',
    icon: Event,
    priv: 'calendar',
    priv: 'hris.calendar'
  },
  {
    key: 'settings-validation',
    title: 'Settings Validation',
    href: '/app/setup/settings-validation',
    icon: SettingsSystemDaydream,
    priv: ['subTypes', 'biz', 'jcd']
  },
  {
    key: 'emp-sched',
    title: 'Employee Shifts',
    href: '/app/setup/employee-shifts',
    icon: Schedule,
    priv: 'emp-sched'
  }
];
