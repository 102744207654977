import React, { createRef, useEffect, useMemo, useState } from 'react';
import {
  TableContainer as Container,
  Table as MuiTable,
  TableHead as Head,
  TableBody as Body,
  TableRow as Row,
  useTheme,
  Box,
  Divider,
  InputAdornment,
  Button,
  LinearProgress,
  Typography,
  TableCell
} from '@material-ui/core';
import Cell from './components/Cell';
import { useStyles } from './hooks';
import {
  generateTotals,
  generateExportableData,
  getCellValue,
  filterTableData,
  filterColumns,
  getCellStyles,
  getPageData
} from './utils';
import DebounceTextField from '../DebounceTextField';
import { CloudDownload, Search } from '@material-ui/icons';
import { jsonToExcel } from 'src/utils';
import { CellCheckBox, TblFilter, TblPagination } from './components';
import { cloneDeep, isFunction } from 'lodash';
import clsx from 'clsx';

function boxShadow(value) {
  return value ? '4px 0 6px rgba(0, 0, 0, 0.1)' : 'none';
}

/**
 *
 * @param {dataTableProps} props
 * @returns
 */
const Table = ({
  columns = [],
  data = [],
  rowIDKey = 'id',
  totals = [],
  hideToolbar = false,
  search = { show: false, fields: [], position: 'left' },
  dataExport = {
    show: false,
    fields: [],
    prepend: [],
    showNo: false,
    includeTotals: false
  },
  title = '',
  toolbar = {
    left: null,
    right: null
  },
  isLoading = false,
  maxHeight = '100%',
  onCellClick = () => {},
  onCellDoubleClick = () => {},
  onCellEdit = () => {},
  getRowClassName = () => {},
  rootContainerProps = {},
  filterValues: parentFilterValues = null,
  updateFilters: updateParentFilters = null,
  searchQuery: parentSearchQuery = null,
  updateSearchQuery: updateParentSearchQuery = null,
  checkboxSelection = false,
  selectionModel = null,
  onSelectionModelChange = null,
  paginate = true,
  pageSize = 10,
  rowsPerPageOptions = [10, 25, 50, 100],
  showRowNo = false
}) => {
  const theme = useTheme();
  const classNames = useStyles();

  const isToolbarEmpty =
    !search?.show && !dataExport?.show && !toolbar?.left && !toolbar?.right;

  const tableFilters = columns.filter(col => col?.filter?.allow);

  const { columns: shownColumns, lastStickyIndex } = filterColumns(columns);
  const columnsTotals = useMemo(() => generateTotals(totals, data), [
    totals,
    data
  ]);

  const [cellRefs, setCellRefs] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const usedSelection = selectionModel || selected;

  const [searchQuery, setSearchQuery] = useState('');
  const [filterValues, setFilterValues] = useState({});

  const colLeft = useMemo(() => {
    const res = [];

    let temp = 0;

    for (const [index, col] of shownColumns.entries()) {
      res.push(col?.sticky && index >= 0 ? temp + 'px' : 'auto');
      temp += col?.width || 200;
    }

    return res;
  }, [shownColumns]);

  const shownData = useMemo(
    () =>
      filterTableData(
        data,
        parentFilterValues || filterValues,
        parentSearchQuery || searchQuery
      ),
    [data, parentFilterValues, filterValues, parentSearchQuery, searchQuery]
  );

  const maxCol = shownColumns.length;
  const maxRow = shownData.length;

  useEffect(() => {
    setPage(0);
  }, [shownData.length]);

  const pageData = useMemo(
    () => (paginate ? getPageData(shownData, page, rowsPerPage) : shownData),
    [paginate, shownData, page, rowsPerPage]
  );

  useEffect(() => {
    setCellRefs(
      Array.from({ length: maxRow }, () =>
        Array.from({ length: maxCol }, () => createRef())
      )
    );
  }, [maxCol, maxRow]);

  function focusCell(rowIndex, colIndex) {
    if (
      colIndex < 0 ||
      colIndex > maxCol - 1 ||
      rowIndex < 0 ||
      rowIndex > maxRow - 1
    )
      return;

    if (cellRefs[rowIndex][colIndex].current) {
      cellRefs[rowIndex][colIndex].current.focus();
    }
  }

  function updateAllSelected() {
    setSelected(state => {
      const newState =
        state.length === 0 ? shownData.map(item => item[rowIDKey]) : [];

      if (isFunction(onSelectionModelChange))
        onSelectionModelChange(cloneDeep(newState));

      return newState;
    });
  }

  /**
   *
   * @param {rowIDKey} id
   * @param {boolean} checked
   */
  function updateSelected(id, checked) {
    setSelected(state => {
      const newState = checked
        ? [...state, id]
        : state.filter(item => item !== id);

      if (isFunction(onSelectionModelChange))
        onSelectionModelChange(cloneDeep(newState));

      return newState;
    });
  }

  function updateFilters(name, value) {
    if (isFunction(updateParentFilters)) updateParentFilters(name, value);

    setFilterValues(state => ({ ...state, [name]: value }));
  }

  function updateSearchQuery(event) {
    if (isFunction(updateParentSearchQuery)) updateParentSearchQuery(event);

    setSearchQuery(event.target.value);
  }

  function exportData() {
    jsonToExcel({
      data: [
        {
          data: generateExportableData(data, columns, {
            showNo: dataExport?.showNo || false,
            includeTotals: dataExport?.includeTotals || false,
            totals: columnsTotals
          }),
          options: { skipHeader: true }
        }
      ],
      fileName: title
    });
  }

  return (
    <Box {...rootContainerProps}>
      {hideToolbar === false && !isToolbarEmpty && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            {search.show === true && search?.position !== 'right' && (
              <DebounceTextField
                variant="outlined"
                size="small"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                value={parentSearchQuery || searchQuery}
                onChange={updateSearchQuery}
              />
            )}
            {toolbar.left}
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            {toolbar.right}
            {dataExport.show === true && (
              <Button
                size="small"
                startIcon={<CloudDownload />}
                variant="contained"
                color="primary"
                onClick={exportData}
              >
                Export
              </Button>
            )}
            {search.show === true && search?.position === 'right' && (
              <DebounceTextField
                variant="outlined"
                size="small"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                value={parentSearchQuery || searchQuery}
                onChange={updateSearchQuery}
              />
            )}
          </Box>
        </Box>
      )}
      {isLoading && <LinearProgress style={{ height: 2 }} />}
      <Divider />
      {tableFilters?.length > 0 && (
        <>
          <TblFilter
            filters={tableFilters}
            filterValues={parentFilterValues || filterValues}
            updateFilters={updateFilters}
            data={data}
          />
          <Divider />
        </>
      )}
      <Container
        style={{ maxHeight }}
        onScroll={event => {
          setScrolled(event.target.scrollLeft > 15);
        }}
      >
        <MuiTable
          className={classNames.alternatingRow}
          style={{
            border: 'none',
            borderCollapse: 'separate',
            borderSpacing: 0
          }}
        >
          <Head>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 20
              }}
            >
              {checkboxSelection === true && (
                <CellCheckBox
                  className={classNames.header}
                  id=""
                  checked={usedSelection.length !== 0}
                  indeterminate={
                    usedSelection.length !== 0 &&
                    usedSelection.length < shownData.length
                  }
                  updateSelected={updateAllSelected}
                />
              )}
              {showRowNo === true && (
                <TableCell
                  className={classNames.header}
                  align="center"
                  style={{
                    width: 60,
                    minWidth: 60,
                    maxWidth: 60
                  }}
                >
                  #
                </TableCell>
              )}
              {shownColumns.map((col, colIndex) => (
                <TableCell
                  key={col.field}
                  className={clsx(classNames.header, col.headerClassName)}
                  style={{
                    width: col?.width || 200,
                    minWidth: col?.width ? col.width : col?.minWidth || 200,
                    textAlign: col?.headerAlign || 'left',
                    position: col.sticky ? 'sticky' : 'static',
                    zIndex: col.sticky ? 15 : 10,
                    left: colLeft[colIndex],
                    boxShadow: boxShadow(
                      colIndex === lastStickyIndex && scrolled
                    )
                  }}
                >
                  {col.headerName}
                </TableCell>
              ))}
            </Row>
          </Head>

          <Body>
            {pageData.map((item, rowIndex) => (
              <Row
                key={item[rowIDKey]}
                className={getRowClassName({
                  id: item[rowIDKey],
                  row: item,
                  columns: shownColumns
                })}
                style={{
                  border: 'none',
                  borderBottom: '1px solid #C4C4C4'
                }}
              >
                {checkboxSelection === true && (
                  <CellCheckBox
                    className={classNames.cellDense}
                    id={item[rowIDKey]}
                    checked={usedSelection.includes(item[rowIDKey])}
                    updateSelected={updateSelected}
                  />
                )}
                {showRowNo === true && (
                  <TableCell
                    className={classNames.cellDense}
                    align="right"
                    style={{
                      width: 60,
                      minWidth: 60,
                      maxWidth: 60,
                      padding: '0 20px'
                    }}
                  >
                    {rowIndex + 1}
                  </TableCell>
                )}
                {shownColumns.map((col, colIndex) => (
                  <Cell
                    key={col.field}
                    onCellDoubleClick={onCellDoubleClick}
                    onCellClick={onCellClick}
                    onCellEdit={onCellEdit}
                    focusCell={focusCell}
                    className={clsx(classNames.cellDense, col.cellClassName)}
                    align={col?.align || 'left'}
                    style={getCellStyles(col, colLeft[colIndex], {
                      boxShadow: boxShadow(
                        colIndex === lastStickyIndex && scrolled
                      )
                    })}
                    col={col}
                    item={item}
                    rowIDKey={rowIDKey}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    cellRef={cellRefs?.[rowIndex]?.[colIndex]}
                  />
                ))}
              </Row>
            ))}
            {totals.length > 0 && shownData.length > 0 && (
              <Row
                style={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 20
                }}
              >
                {checkboxSelection === true && (
                  <TableCell
                    className={classNames.cellDense}
                    style={{
                      width: 50,
                      minWidth: 50,
                      maxWidth: 50,
                      padding: 0
                    }}
                  />
                )}
                {showRowNo === true && (
                  <TableCell
                    className={classNames.cellDense}
                    style={{
                      width: 60,
                      minWidth: 60,
                      maxWidth: 60,
                      padding: '0 20px'
                    }}
                  />
                )}
                {shownColumns.map((col, colIndex) => (
                  <TableCell
                    key={col.field}
                    align={col?.align || 'left'}
                    className={clsx(classNames.cellDense, col.cellClassName)}
                    style={getCellStyles(col, colLeft[colIndex], {
                      borderTop: '2px solid #C4C4C4',
                      fontWeight: 700,
                      boxShadow: boxShadow(
                        colIndex === lastStickyIndex && scrolled
                      ),
                      paddingLeft: 20,
                      paddingRight: 20
                    })}
                  >
                    {totals.includes(col.field)
                      ? getCellValue({
                          col,
                          row: {},
                          id: 'total',
                          value: columnsTotals[col.field],
                          field: col.field,
                          hasFocus: false
                        })
                      : ''}
                  </TableCell>
                ))}
              </Row>
            )}
          </Body>
        </MuiTable>
      </Container>
      {shownData.length === 0 && (
        <Box
          height={52}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderBottom="1px solid #C4C4C4"
        >
          <Typography variant="body2">No Data</Typography>
        </Box>
      )}
      {paginate === true && (
        <TblPagination
          totalItems={maxRow}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </Box>
  );
};
export default React.memo(Table);
